/*----------------------------------------*\
  v-click-outside
  https://www.npmjs.com/package/v-click-outside
\*----------------------------------------*/

import type { DirectiveBinding } from "vue";
import { app } from "../app";
import vClickOutside from "v-click-outside";
const { bind, unbind } = vClickOutside.directive;

app.directive("click-outside", {
  mounted(el: any, binding: DirectiveBinding) {
    bind(el, { value: binding.value });
  },
  beforeUnmount(el: any) {
    unbind(el);
  },
});
